import 'bootstrap/dist/css/bootstrap.css';

export default function Section1() {
  return (
    <section className="section1 text-center">
      <div className="container">
        <div className="d-xl-flex justify-content-between">
          <div className="ms-lg-4 content">
            <p className="title">AIエフェクトを体験せよ！</p>
            <p className="text-content">
              クリエイティブな
              <br />
              バスケ動画アプリが登場！
            </p>
            <img src="/img/logo.png" width={148} alt="icon"></img>
            <div className="d-sm-flex justify-content-center align-items-center wrapper">
              <p className="my-lg-auto me-lg-3 wrapper-text">
                今すぐ簡単！
                <br />
                無料ダウンロード！
              </p>
              <a href="https://apps.apple.com/jp/app/baller-%E3%83%90%E3%82%B9%E3%82%B1%E5%B0%82%E7%94%A8ai%E3%82%A8%E3%83%95%E3%82%A7%E3%82%AF%E3%83%88%E3%82%A2%E3%83%97%E3%83%AA/id1582782172">
                <img src="/img/App_Store_Badge_1.png" alt="app-store" className="wrapper-icon"></img>
              </a>
            </div>
          </div>
          <img src="/img/mockup.png" alt="anh111" className="me-lg-3 phone-image"></img>
        </div>
      </div>
    </section>
  );
}
