export default function Section2() {
  return (
    <section className="section2 text-center">
      <div className="container">
        <div className="row align-items-center gx-lg-5">
          <div className="col-xl-5">
            <div className="text-xl-start section2--text">
              <h1 className="section2--title">
                プレイ動画から<span>AI</span>
                が自動で
                <br />
                ボールを<span>認識</span>
              </h1>
              <p className="section2--content">
                エフェクトを選択するだけで
                <br />
                あなただけのオリジナル動画を作成できます。
              </p>
            </div>
          </div>
          <div className="col-xl-7">
            <div className="row g-5">
              <div className="col-xl">
                <img src="/img/Group_65.png" alt="phone" className=" section2--first-img"></img>
              </div>
              <div className="col-xl">
                <img src="/img/Group_66.png" alt="phone"></img>
              </div>
              <div className="col-xl">
                <img src="/img/Group_67.png" alt="phone"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
