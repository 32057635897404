export default function Section3() {
  return (
    <section>
      <div className="px-lg-5 mx-lg-5 mx-xl-auto container">
        <div className="ms-lg-5 text-center text-lg-start">
          <h1 className="section--title">Play</h1>
          <p className="section3--text">Ballerの楽しみ方</p>
        </div>
      </div>

      <div className="px-lg-5 mx-lg-5 mx-xl-auto container">
        <div className="row gx-lg-5 justify-content-between text-center text-lg-start">
          <div className="col-lg ms-lg-5">
            <img className="section3--img1" src="/img/AdobeStock_228561600_1.png" alt="ball"></img>
          </div>
          <div className="col-lg mx-lg-5 section-right">
            <div className="mx-auto mx-lg-0 mb-4 section03--01">01</div>
            <h1 className="section03--01-title">ボールにAIエフェクトを描写</h1>
            <hr className="divider-1"></hr>
            <p className="section03--01-text">
              動画のバスケットボールをAIが自動で認識し、コミカルなエフェクトを描写可能。非現実的な動画を作成することが出来ます。
            </p>
          </div>
        </div>
      </div>
      <div className="d-lg-block d-none px-lg-5 mx-lg-5 mx-xl-auto container">
        <div className="row gx-5 justify-content-between text-center text-lg-start">
          <div className="col-lg mx-lg-5 section-left">
            <div className="mx-auto mx-lg-0 mb-4 section3--02">02</div>
            <h1 className="section3--02-title">豊富なBGMを選択</h1>
            <hr className="divider-2"></hr>
            <p className="section3--02-text">
              撮影した動画にBGMを追加することで、あなただけのオリジナル動画に仕上がります。
            </p>
          </div>
          <div className="me-lg-5 col-lg">
            <img src="/img/Mask_group.png" alt="anh" className="section03--01-img section-right"></img>
          </div>
        </div>
      </div>

      <div className="d-lg-none d-block px-lg-5 mx-lg-5 mx-xl-auto container">
        <div className="me-lg-5 col-lg">
          <img src="/img/Mask_group.png" alt="anh" className="section03--01-img section-right"></img>
        </div>
        <div className="row gx-5 justify-content-between text-center text-lg-start">
          <div className="col-lg ms-lg-5 section-left">
            <div className="mx-auto mx-lg-0 mb-4 section3--02">02</div>
            <h1 className="section3--02-title">豊富なBGMを選択</h1>
            <hr className="divider-2"></hr>
            <p className="section3--02-text">
              撮影した動画にBGMを追加することで、あなただけのオリジナル動画に仕上がります。
            </p>
          </div>
        </div>
      </div>

      <div className="px-lg-5 mx-lg-5 mx-xl-auto container">
        <div className="row gx-lg-5 justify-content-between text-center text-lg-start">
          <div className="col-lg ms-lg-5">
            <img src="/img/AdobeStock_339881121_1.png" alt="anh" className="section03--02-img"></img>
          </div>
          <div className="col-lg mx-lg-5 section-right">
            <div className="mx-auto mx-lg-0 mb-4 section03--03">03</div>
            <h1 className="section03--03-title">SNSに簡単投稿</h1>
            <hr className="divider-3"></hr>
            <p className="section03--03-text">
              作成した動画は自由に利用できます。
              <br />
              SNSでみんなに自慢しよう！
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
