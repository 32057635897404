export default function Section5() {
  return (
    <section style={{ backgroundColor: '#FFF7F3', paddingBottom: '120px' }}>
      <div className="container">
        <div className="text-center text-lg-start">
          <h1 className="section--title mb-3">Step</h1>
          <p className="section--text">４ステップで簡単に挑戦</p>
        </div>
      </div>

      <div className="container">
        <div className="row ">
          <div className="col-lg-3 section5--column">
            <div className="section5--step">
              <span>STEP1</span>
            </div>
            <img src="/img/Step1.png" alt="phone"></img>
            <p className="w-75 text-center text-lg-start section5--text">まずはApp Storeから無料でダウンロード！</p>
          </div>
          <div className="col-lg-3 section5--column">
            <div className="section5--step">
              <span>STEP2</span>
            </div>
            <img src="/img/step2.png" alt="phone"></img>
            <p className="w-75 text-center text-lg-start section5--text">
              バスケットボールを使って動画を撮影しよう！保存した動画にも対応しています。
            </p>
          </div>
          <div className="col-lg-3 section5--column">
            <div className="section5--step">
              <span>STEP3</span>
            </div>
            <img src="/img/Step3.png" alt="phone"></img>
            <p className="w-75 text-center text-lg-start section5--text">
              好きなエフェクトを選択しよう！BGMも追加できます。
            </p>
          </div>
          <div className="col-lg-3 section5--column">
            <div className="section5--step">
              <span>STEP4</span>
            </div>
            <img src="/img/Step4.png" alt="phone"></img>
            <p className="w-75 text-center text-lg-start section5--text">各種SNSに動画を簡単に投稿できます。</p>
          </div>
        </div>
      </div>
    </section>
  );
}
